"use client";

import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { getLocalStorage, removeLocalStorage } from "src/lib/utils/localStorage";

export function LogoutComponent({ token, baseUrl }: Readonly<{ token: string; baseUrl: string }>) {
  const router = useRouter();

  useEffect(() => {
    if (!token) {
      console.log("No token found");
    }
    fetch(baseUrl + "/api/v1/logout/", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token ?? getLocalStorage("token")}`,
      },
      referrer: baseUrl,
      referrerPolicy: "origin-when-cross-origin",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Logout error");
        }
        Cookies.remove("auth_token", { sameSite: "strict", secure: true });
        Cookies.remove("auth_token", { domain: ".caltana.com", sameSite: "strict", secure: true });
        Cookies.remove("csrftoken", { secure: true });
        Cookies.remove("sessionid", { secure: true });
        removeLocalStorage("user");
        removeLocalStorage("auth_token");
        removeLocalStorage("token");
      })
      .finally(() => {
        const returnUrlParam = new URLSearchParams(window.location.search).get("returnUrl");
        const loginUrl = new URL("/login", window.location.origin);
        if (returnUrlParam) {
          loginUrl.searchParams.set("returnUrl", returnUrlParam);
        }
        router.push(loginUrl.toString());
      });
  }, [baseUrl, router, token]);

  return (
    <div>
      <h1>Logging out</h1>
    </div>
  );
}
